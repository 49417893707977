import type { BrowserOptions } from '@sentry/react';
import {
  captureException,
  init,
  reactRouterV6BrowserTracingIntegration,
  wrapCreateBrowserRouter,
} from '@sentry/react';
import { useEffect } from 'react';
import {
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

const options: BrowserOptions = {
  dsn: 'https://f990ff615fe94a79bb201f13dae329a7@o372675.ingest.sentry.io/6021632',
  integrations: [
    reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
};

export const SentryService = {
  init: (config: BrowserOptions = {}) => {
    const isProd = process.env.NODE_ENV === 'production';
    const environment = window.location.href.includes('stage') ? 'stage' : 'production';
    const release = process.env.RELEASE_NAME;

    if (isProd && release) {
      init({
        ...options,
        ...config,
        environment,
        release,
      });
    }
  },
  trackError: captureException,
  sentryCreateBrowserRouter: wrapCreateBrowserRouter(createBrowserRouter),
};
